// export { default } from '@contents/Home';
/* eslint-disable no-console */
import React from 'react';

import { customApi } from '@utils';

import Home from '../contents/Home';

const HomeRoute = (props) => <Home {...props} />;

export async function getStaticProps() {
  const response = await customApi
    .get('/v1/home-banners')
    .then(({ data }) => {
      const banners = data;
      return {
        banners,
      };
    })
    .catch((error) => console.error('Error in build the home page: ', error));

  const revalidate = Number(process.env.REVALIDATE_TIME) || 43200;

  return {
    props: { ...response },
    revalidate,
  };
}

export default HomeRoute;
