import { useState, useCallback } from 'react';

import { customApi } from '@utils';

const useFetchCategories = () => {
  const [depositCategories, setDepositCategories] = useState(null);
  const [depositCategoriesLoading, setDepositCategoriesLoading] = useState(true);
  const [depositCategoriesError, setDepositPhotosError] = useState('');

  const fetchCategories = useCallback(async (offset = 1, perPage = 12, callback) => {
    setDepositCategoriesLoading(true);

    await customApi(`/depositphotos/category?page=${offset}&perPage=${perPage}`)
      // eslint-disable-next-line consistent-return
      .then((res) => {
        if (res.status === 200) {
          setDepositCategories({ ...res.data });
          callback(res.data);
        }
      })
      .catch((error) => {
        setDepositPhotosError(error);
      });

    setDepositCategoriesLoading(false);
  }, []);

  return {
    fetchCategories,
    depositCategories,
    depositCategoriesLoading,
    depositCategoriesError,
  };
};

export default useFetchCategories;
