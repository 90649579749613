import { theme, Box } from '@printi/printi-components';
import styled from 'styled-components';
const { breakpoint, space, color } = theme;

export const Container = styled.div`
  margin-top: ${space.xl3};
  padding: 0 ${space.md};

  @media (min-width: ${breakpoint.lg}) {
    padding: 0;
  }
  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-slide {
    display: flex;
    align-self: stretch;
    > div {
      display: flex;
      align-self: stretch;
      height: 100%;
    }
  }

  .slick-arrow {
    background-color: transparent;
    width: ${space.xl};
    height: ${space.xl};
    color: ${color.info.lighter};
    transition: 0.3s ease;
    transform: scale(2);
    margin-top: -${space.xl4};

    &:hover {
      color: ${color.info.pure};
    }

    &:before {
      display: none;
    }

    &:focus {
      box-shadow: 0 0 0 1px ${color.grayscale.black};
    }
  }
`;

export const BoxReviews = styled(Box)`
  margin: ${space.md};
  min-height: 33rem !important;
`;

export const Picture = styled.div`
  display: block;
  padding: ${space.md};

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;
