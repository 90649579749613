import { theme, Row } from '@printi/printi-components';
import styled, { keyframes } from 'styled-components';

const { breakpoint, borders, font, space } = theme;

export const Section = styled.section`
  margin-bottom: ${space.xl3};
  overflow: hidden;
`;

export const Product = styled.div`
  display: inline-flex;
  vertical-align: top;
  width: 50%;

  @media (min-width: ${breakpoint.lg}) {
    width: 33.33%;
  }

  @media (min-width: ${breakpoint.xl}) {
    width: 25%;
  }
`;

export const SliderWrap = styled(Row)`
  .slick-list {
    overflow: visible;
  }

  .slick-dots li button:before {
    font-size: ${font.size.h4};
  }
`;

export const CardHighlightWrap = styled.div`
  padding: 0 ${space.xs};
`;

export const Picture = styled.picture`
  display: block;
  width: 100%;
  min-height: 10rem;

  img,
  source {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const PicturePrintersCollection = styled(Picture)`
  img,
  source {
    border-radius: ${borders.radius.lg};
    cursor: pointer;
  }
`;

const colorBlink = (props) => keyframes`
  0% {
    background-color: ${props.theme.colors.grayscale.gray};
  }

  50% {
    background-color: ${props.theme.colors.grayscale.dark};
  }

  100% {
    background-color: ${props.theme.colors.grayscale.gray};
  }
`;

export const SliderPlaceHolder = styled.div`
  display: block;
  width: 100%;
  height: 12rem;
  background-color: gray;
  margin-bottom: ${space.xl};
  animation: ${(p) => colorBlink(p)} 2s infinite;

  @media (min-width: ${breakpoint.sm}) {
    height: 14rem;
  }

  @media (min-width: ${breakpoint.md}) {
    height: 17rem;
  }

  @media (min-width: ${breakpoint.lg}) {
    height: 22rem;
  }
`;

export const SettingsPlaceHolder = styled.div`
  &:before,
  &:after {
    content: '';
    display: block;
    margin: 0 auto ${space.xl};
    animation: ${colorBlink} 2s infinite;
  }

  &:before {
    width: 26rem;
    max-width: 80%;
    height: 30px;
  }

  &:after {
    width: 14rem;
    max-width: 70%;
    height: 40px;
  }
`;

export const CardProductPlaceholder = styled.div`
  display: block;
  width: 100%;
  height: 16rem;
  margin: 0 ${space.xs} ${space.xl};
  animation: ${colorBlink} 2s infinite;
`;

export const BannerText = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;
