import React, { memo } from 'react';
import Slider from 'react-slick';

import { theme, Row, Col, Button } from '@printi/printi-components';
import { useWindowSize, setDataLayerEvent } from '@utils';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import * as S from './Home.styles';

const { lg } = theme.media;

const Banner = ({ banners }) => {
  const router = useRouter();
  const windowSize = useWindowSize();
  const hasPad = windowSize.width <= lg * 16;
  const cloudFront = process.env.CLOUDFRONT;

  const handleRedirect = (url_link, event) => {
    setDataLayerEvent(event);
    router.push(url_link, { shallow: true });
  };

  return (
    <>
      <Row isFull={hasPad}>
        <Col xs={12} noGutter={hasPad}>
          <Slider arrows={false} autoplay={false} speed={1000}>
            {banners?.map(({ title, position, image, mobile_image, url_link, target_link }) => (
              <>
                <S.BannerText>
                  <h1>Decora Printi</h1>
                  <p>
                    Transforme seu ambiente com quadros e telas. Envie direto do seu celular ou escolha uma de nossas
                    centenas de opções.
                  </p>
                </S.BannerText>
                <a
                  key={position}
                  href={url_link}
                  title={title}
                  target={target_link}
                  onClick={() => handleRedirect(url_link, 'decoraPrinterBanner')}
                >
                  <S.Picture>
                    <source media="(min-width: 769px)" srcSet={cloudFront + image.path} />
                    <source media="(max-width: 768px)" srcSet={cloudFront + mobile_image.path} />
                    <img src={cloudFront + mobile_image.path} alt={image.alt} />
                  </S.Picture>
                </a>
              </>
            ))}
          </Slider>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={8} md={6} xl={4} smOffset={2} mdOffset={3} xlOffset={4} style={{ margin: '0 auto' }}>
          <Button
            as="a"
            isBlock
            kind="info"
            style={{ margin: '1.5rem 0', padding: '1.5rem 0' }}
            href="/configuracao-do-quadro"
            onClick={() => handleRedirect('/configuracao-do-quadro', 'configuracao-do-quadro')}
          >
            Começar a personalizar
          </Button>
        </Col>
      </Row>
    </>
  );
};

Banner.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      mobile_image: PropTypes.shape({
        file: PropTypes.string.isRequired,
      }).isRequired,
      image: PropTypes.shape({
        file: PropTypes.string.isRequired,
      }).isRequired,
      url_link: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default memo(Banner);
