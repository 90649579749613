import React, { memo } from 'react';
import Slider from 'react-slick';

import { Row, Col, Text, Icon, theme } from '@printi/printi-components';
import Image from 'next/image';
import PropTypes from 'prop-types';

import reviewsList from './reviews-list';
import * as S from './styles';
const { color } = theme;

const SliderArrow = ({ position, currentSlide, slideCount, ...props }) => {
  let cssClasses = `slick-prev slick-arrow ${currentSlide === 0 ? 'slick-disabled' : ''}`;
  let isDisabled = currentSlide === 0;

  if (position === 'right') {
    cssClasses = `slick-next slick-arrow ${currentSlide === slideCount - 1 && 'slick-disabled'}`;
    isDisabled = currentSlide === slideCount - 1;
  }

  return (
    <button {...props} className={cssClasses} aria-hidden="true" aria-disabled={isDisabled} type="button">
      {position === 'left' && <Icon fill={color.info.lighter} name="ArrowIosBack" />}
      {position === 'right' && <Icon fill={color.info.lighter} name="ArrowIosForward" />}
    </button>
  );
};

SliderArrow.propTypes = {
  onClick: PropTypes.func,
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  position: PropTypes.oneOf(['left', 'right']),
};

const settings = {
  autoplay: true,
  lazyLoad: true,
  dots: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 30000,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  prevArrow: <SliderArrow position="left" />,
  nextArrow: <SliderArrow position="right" />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        autoplaySpeed: 20000,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 10000,
      },
    },
  ],
};

const CustomerReviews = () => (
  <Row>
    <Col xs={12}>
      <S.Container>
        <Text color="info" as="h3" size="xlg" weight="bold" align="center" margin="1.5rem 0 0">
          Pessoas que tiveram bons momentos com nossos quadros:
        </Text>
        <Slider {...settings}>
          {reviewsList.map(({ id, image, name, comment }) => (
            <S.BoxReviews key={id}>
              <S.Picture>
                <Image src={image.file} alt={name} width={500} height={500} loading="lazy" />
              </S.Picture>
              <Text weight="bold" align="center" margin="0 0 0.5rem">
                {name}
              </Text>
              <Text size="sm" margin="0 1rem 1rem">
                {comment}
              </Text>
            </S.BoxReviews>
          ))}
        </Slider>
      </S.Container>
    </Col>
  </Row>
);

export default memo(CustomerReviews);
