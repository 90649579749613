import React, { memo } from 'react';

import { Row, Col, Text } from '@printi/printi-components';
import Link from 'next/link';
import { useRouter } from 'next/router';

import * as S from './Home.styles';

const PrintersCollection = () => {
  const router = useRouter();

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      router.push('concurso-decora-printers');
    }
  };

  return (
    <Row>
      <Col xs={12} md={12}>
        <Text as="h3" color="weak" size="xlg" weight="bold" margin="1rem 0">
          Coleção Printers
        </Text>
      </Col>
      <Col xs={12} md={12}>
        <Link href="concurso-decora-printers">
          <S.PicturePrintersCollection
            tabIndex="0"
            onKeyDown={(e) => {
              handleKeyDown(e);
            }}
          >
            <source media="(min-width: 769px)" srcSet="/images/cta-decora-colecao-printers.webp" />
            <source media="(max-width: 768px)" srcSet="/images/cta-decora-colecao-printers-mobile.webp" />
            <img src="/images/cta-decora-colecao-printers-mobile.webp" alt="CTA Coleção Printers" loading="lazy" />
          </S.PicturePrintersCollection>
        </Link>
      </Col>
    </Row>
  );
};

export default memo(PrintersCollection);
