import React, { memo, useEffect } from 'react';

import LoaderDecora from '@components/LoaderDecora';
import Page from '@components/Page';
import { renderMerchantCenterBadge, removeMerchantCenterBadge, setMerchantCenterBadge, handlePageView } from '@utils';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

const Banner = dynamic(() => import('./Banner'), {
  loading: () => <LoaderDecora kind="light" size="medium" />,
});
const PrintersCollection = dynamic(() => import('./PrintersCollection'));
const CustomerReviews = dynamic(() => import('@components/CustomerReviews'));
const DepositPhotosCollection = dynamic(() => import('@components/DepositPhotosCollection'));

const SEO = {
  title: 'Decora Printi | Você só precisa imaginar',
  description: 'Monte seus quadros com o Decora Printi',
  copyright: 'Decora Printi',
  abstract: 'Decora Printi | Você só precisa imaginar',
  robots: 'index, follow',
  type: 'website',
};

const Home = ({ banners }) => {
  const router = useRouter();

  useEffect(() => {
    renderMerchantCenterBadge();
    setMerchantCenterBadge();
    handlePageView(router.asPath);
    return () => {
      removeMerchantCenterBadge();
    };
  }, [router.asPath]);

  return (
    <Page seo={SEO}>
      {banners && <Banner banners={banners} />}
      <DepositPhotosCollection title="Precisando de Ideias?" page="home" />
      <PrintersCollection />
      <CustomerReviews />
    </Page>
  );
};

Home.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      mobile_image: PropTypes.shape({
        file: PropTypes.string.isRequired,
      }).isRequired,
      image: PropTypes.shape({
        file: PropTypes.string.isRequired,
      }).isRequired,
      url_link: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default memo(Home);
