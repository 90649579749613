import React, { memo, useCallback, useEffect, useState } from 'react';

import LoaderDecora from '@components/LoaderDecora';
import { Row, Col, Text, Button, Box } from '@printi/printi-components';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import * as S from './styles';
import useFetchCategories from './useFetchCategories';

const DepositPhotosCollection = ({ title, page }) => {
  const router = useRouter();
  const [items, setItems] = useState([]);
  const { fetchCategories, depositCategories, depositCategoriesLoading } = useFetchCategories();
  const [offset, setOffset] = useState(1);
  const [nrPage, setNrPage] = useState(1);
  const [perPage] = useState(page === 'home' ? 4 : 12);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const categoriesCallback = (categoriesInfo) => {
    setNrPage(categoriesInfo.pagination.total_pages);
    setItems([...items, ...categoriesInfo.items]);
  };

  useEffect(() => {
    fetchCategories(offset, perPage, categoriesCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCategories, offset, perPage]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected ?? offset;
    setOffset(selectedPage + 1);
  };

  const handleLinkHighlight = useCallback(
    (e, href) => {
      e.preventDefault();
      router.push(href, undefined, { shallow: true });
    },
    [router],
  );

  if (items.length === 0) {
    return '';
  }

  const defaultSearchCategories = ['Abstrato', 'Animais', 'Geométrico', 'Paisagem'];
  const cloudFront = process.env.CLOUDFRONT;

  return (
    <Row>
      {page !== 'home' && (
        <Col xs={12} md={12}>
          <Text as="h3" color="weak" size="xlg" weight="bold" margin="1rem 0 0">
            Principais pesquisas
          </Text>
          <Box kind="default" noBorder type="default" backgroundOpacity style={{ marginTop: '1rem' }}>
            <S.ButtonInfo
              as="a"
              kind="info"
              isPill
              isCircle
              onClick={(e) => handleLinkHighlight(e, '/concurso-decora-printers')}
              href="/concurso-decora-printers"
            >
              Decora Printers
            </S.ButtonInfo>
            {defaultSearchCategories.map((defaultSearchCategory) => (
              <S.ButtonInfo
                key={defaultSearchCategory}
                as="a"
                kind="info"
                isPill
                isCircle
                onClick={(e) => handleLinkHighlight(e, `/canvas-ideias?p=${encodeURIComponent(defaultSearchCategory)}`)}
                href={`/canvas-ideias?p=${encodeURIComponent(defaultSearchCategory)}`}
              >
                <S.ScreenReaderText>Quadros de</S.ScreenReaderText>
                {defaultSearchCategory}
              </S.ButtonInfo>
            ))}
          </Box>
        </Col>
      )}
      {title && (
        <Col xs={12} md={12}>
          <Text as="h3" color="weak" size="xlg" weight="bold" margin="1rem 0 0">
            {title}
          </Text>
        </Col>
      )}
      <Col xs={12} md={12}>
        <S.Collection key={depositCategories}>
          {items.map((category) => (
            <S.Highlight
              key={category.slug}
              dark
              imageUrl={cloudFront + category?.dp_image?.path}
              title={category.title}
              priceTitle="a partir de "
              price="R$ 69,90"
              onClick={(e) => handleLinkHighlight(e, `/canvas-ideias/${category.slug}`)}
              onKeyPress={(e) => e.key === 'Enter' && handleLinkHighlight(e, `/canvas-ideias/${category.slug}`)}
            >
              <S.CollectionButton as="a" kind="inverse" href={`/canvas-ideias/${category.slug}`}>
                Ver quadros <S.ScreenReaderText>da categoria {category.title}</S.ScreenReaderText>
              </S.CollectionButton>
            </S.Highlight>
          ))}
        </S.Collection>
        {depositCategoriesLoading && <LoaderDecora size="medium" kind="light" />}
      </Col>
      <S.WrapperButton>
        {!depositCategoriesLoading && nrPage > 1 && offset < nrPage && page !== 'home' && (
          <Button kind="info" isPill style={{ margin: '2rem auto' }} onClick={(e) => handlePageClick(e)}>
            Veja todas as categorias
          </Button>
        )}
        {page !== 'home' && (
          <S.ContainerBox backgroundOpacity noBorder>
            <Text size="lg" align="center" margin="5rem 0 0" weight="bold">
              Já tem uma foto para transformar em quadro?
            </Text>
            <Col xs={12} sm={8} md={6} xl={4} smOffset={2} mdOffset={3} xlOffset={4}>
              <Button
                as="a"
                kind="info"
                isPill
                onClick={(e) => handleLinkHighlight(e, '/configuracao-do-quadro')}
                href="/configuracao-do-quadro"
                style={{ margin: '2rem auto' }}
              >
                Crie seu quadro agora!
              </Button>
            </Col>
          </S.ContainerBox>
        )}
        {page === 'home' && (
          <Col xs={12} sm={8} md={6} xl={4} smOffset={2} mdOffset={3} xlOffset={4}>
            <Button
              as="a"
              kind="info"
              isPill
              isOutlined
              style={{ margin: '2rem auto' }}
              onClick={(e) => handleLinkHighlight(e, '/canvas-ideias')}
              href="/canvas-ideias"
            >
              Veja todas as categorias
            </Button>
          </Col>
        )}
      </S.WrapperButton>
    </Row>
  );
};
DepositPhotosCollection.propTypes = {
  title: PropTypes.string.isRequired,
  page: PropTypes.string,
};
export default memo(DepositPhotosCollection);
