import styled from '@emotion/styled';
import { theme, Row, CardHighlight, Box, Button } from '@printi/printi-components';
const { breakpoint, space, color } = theme;

export const Collection = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: ${space.xl} 0;
`;
export const Highlight = styled(CardHighlight)`
  background-blend-mode: multiply;
  background-color: #a547d7 !important;
  margin: ${space.xs};
  width: 44%;
  @media (min-width: ${breakpoint.lg}) {
    width: 31%;
  }

  @media (min-width: ${breakpoint.xl}) {
    width: 23%;
  }
`;

export const WrapperBanner = styled(Row)`
  background-color: ${color.info.pale};
  padding: ${space.xl3} 0;
`;

export const WrapperButton = styled(Row)`
  justify-content: center;
  display: block;
`;

export const CollectionButton = styled(Button)`
  min-width: 5rem !important;
  width: 100% !important;
`;

export const ButtonInfo = styled(Button)`
  margin: ${space.xs};
  float: left;
`;

export const ContainerBox = styled(Box)`
  padding: 0 2rem;
  justify-content: center;
  margin: 0 auto;
  button {
    text-align: center;
    margin: 2rem auto 0;
  }
`;

export const ScreenReaderText = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;
