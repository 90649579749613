const reviews = [
  {
    id: '1',
    name: 'Leonardo J.',
    comment:
      'Quadro de excelente qualidade! Impressão, moldura e embalagem muito bem feitas. De todos os concorrentes, o Decora tem o melhor sistema de impressão! Com simulação, ajuste de fotos e indicação de tamanho de acordo com a qualidade da imagem. Obrigada!',
    image: {
      file: '/images/reviews/leonardo.webp',
    },
  },
  {
    id: '2',
    name: 'Alessandra M.',
    comment:
      'Estava atrás de alguns quadros para as prateleiras aqui do home office. Pensei em imprimir e adquirir a moldura por fora, e descobri que a Printi tinha um produto prontinho assim, por um bom custo benefício.',
    image: {
      file: '/images/reviews/alessandra.webp',
    },
  },
  {
    id: '3',
    name: 'Alysson D.',
    comment:
      'Fiz 2 quadros no site. A produção foi super rápida, recebi meus quadros muito bem embalados em casa. A qualidade de impressão é excelente e as cores são bem vibrantes. Já estou planejando os próximos para o meu escritório!',
    image: {
      file: '/images/reviews/alysson.webp',
    },
  },
  {
    id: '4',
    name: 'Bruna D.',
    comment:
      'Fiquei muito feliz com o resultado do produto! Meus desenhos ficaram exatamente como imaginei no quadro e a qualidade de impressão e moldura eram ótimas. Além disso, precisei de uma entrega rápida e fui muito bem atendida, chegaram antes do prazo!',
    image: {
      file: '/images/reviews/bruna.webp',
    },
  },
  {
    id: '5',
    name: 'Maria C.',
    comment:
      'Comprei o primeiro quadro para presentear dois parentes no Natal (retrato ilustrado dos filhos). Eles gostaram tanto que indicaram e eu iniciei um novo negócio! Agora tenho tranquilidade em criar artes pois tenho um fornecedor de excelente qualidade!',
    image: {
      file: '/images/reviews/maria.webp',
    },
  },
  {
    id: '6',
    name: 'Rafaela M.',
    comment:
      'Meu quadro chegou mais rápido do que eu esperava por ser final de ano. Super bem embalado e seguro. \n' +
      'Pelo tamanho da imagem, fiquei com medo da qualidade ficar ruim ou esticada e no fim saí apaixonada! A qualidade da impressão é INCRÍVEL, recomendo!\n',
    image: {
      file: '/images/reviews/rafaela.webp',
    },
  },
  {
    id: '7',
    name: 'Vitória S.',
    comment:
      'A experiência foi ótima. Tanto a qualidade da moldura como da impressão me deixaram muito satisfeita. Deu mais vida a minha ilustração, fiquei muito feliz com o resultado final!',
    image: {
      file: '/images/reviews/vitoria.webp',
    },
  },
  {
    id: '8',
    name: 'Ana L.',
    comment:
      'O quadro simplesmente superou todas as expectativas. A qualidade do produto entregue e os acabamentos são impecáveis!',
    image: {
      file: '/images/reviews/ana.webp',
    },
  },
  {
    id: '9',
    name: 'Daiane M.',
    comment:
      'Amei meu Canvas! A impressão é de alta qualidade e o acabamento é perfeito! Escolhi uma arte com uma oração para decorar a minha cozinha. Foi super fácil a compra, ágil a entrega e o produto é impecável. Recomendo o Decora Printi a todos!',
    image: {
      file: '/images/reviews/daiane.webp',
    },
  },
  {
    id: '10',
    name: 'Lewi M.',
    comment:
      'Sou fotógrafo e o resultado da impressão em tela foi muito bom. Fiquei bastante satisfeito com essa experiência, certamente farei outros!',
    image: {
      file: '/images/reviews/lewi.webp',
    },
  },
];

export default reviews;
